// DO NOT EDIT THIS FILE
// This file has been generated by gen-api-models
// eslint-disable sonar/max-union-size
// eslint-disable sonarjs/no-identical-functions

import * as t from "io-ts";

import * as r from "@pagopa/ts-commons/lib/requests";

import { PaymentRequestsGetResponse } from "./PaymentRequestsGetResponse";

import { ProblemJson } from "./ProblemJson";

import { PaymentProblemJson } from "./PaymentProblemJson";

import { PaymentActivationsPostRequest } from "./PaymentActivationsPostRequest";

import { PaymentActivationsPostResponse } from "./PaymentActivationsPostResponse";

import { PaymentActivationsGetResponse } from "./PaymentActivationsGetResponse";

import { RecipientRequest } from "./RecipientRequest";

import { RecipientResponse } from "./RecipientResponse";

import { BrowserInfoResponse } from "./BrowserInfoResponse";

/****************************************************************
 * getPaymentInfo
 */

// Request type definition
export type GetPaymentInfoT = r.IGetApiRequestType<
  { readonly rptId: string; readonly recaptchaResponse: string },
  never,
  never,
  | r.IResponseType<200, PaymentRequestsGetResponse, never>
  | r.IResponseType<400, ProblemJson, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<500, PaymentProblemJson, never>
>;

export const getPaymentInfoDefaultResponses = {
  200: PaymentRequestsGetResponse,
  400: ProblemJson,
  401: t.undefined,
  500: PaymentProblemJson
};

export type GetPaymentInfoResponsesT<
  A0 = PaymentRequestsGetResponse,
  C0 = PaymentRequestsGetResponse,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = PaymentProblemJson,
  C3 = PaymentProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  500: t.Type<A3, C3>;
};

export function getPaymentInfoDecoder<
  A0 = PaymentRequestsGetResponse,
  C0 = PaymentRequestsGetResponse,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = PaymentProblemJson,
  C3 = PaymentProblemJson
>(
  overrideTypes:
    | Partial<GetPaymentInfoResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<400, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<500, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getPaymentInfoDefaultResponses as unknown) as GetPaymentInfoResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, never>(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        never
      >(400, type[400])) as r.ResponseDecoder<r.IResponseType<400, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, never>(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        never
      >(500, type[500])) as r.ResponseDecoder<r.IResponseType<500, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getPaymentInfoDefaultDecoder = () => getPaymentInfoDecoder();

/****************************************************************
 * activatePayment
 */

// Request type definition
export type ActivatePaymentT = r.IPostApiRequestType<
  { readonly body: PaymentActivationsPostRequest },
  "Content-Type",
  never,
  | r.IResponseType<200, PaymentActivationsPostResponse, never>
  | r.IResponseType<400, ProblemJson, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<500, PaymentProblemJson, never>
>;

export const activatePaymentDefaultResponses = {
  200: PaymentActivationsPostResponse,
  400: ProblemJson,
  401: t.undefined,
  500: PaymentProblemJson
};

export type ActivatePaymentResponsesT<
  A0 = PaymentActivationsPostResponse,
  C0 = PaymentActivationsPostResponse,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = PaymentProblemJson,
  C3 = PaymentProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  500: t.Type<A3, C3>;
};

export function activatePaymentDecoder<
  A0 = PaymentActivationsPostResponse,
  C0 = PaymentActivationsPostResponse,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = PaymentProblemJson,
  C3 = PaymentProblemJson
>(
  overrideTypes:
    | Partial<ActivatePaymentResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<400, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<500, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((activatePaymentDefaultResponses as unknown) as ActivatePaymentResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, never>(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        never
      >(400, type[400])) as r.ResponseDecoder<r.IResponseType<400, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, never>(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        never
      >(500, type[500])) as r.ResponseDecoder<r.IResponseType<500, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const activatePaymentDefaultDecoder = () => activatePaymentDecoder();

/****************************************************************
 * getActivationStatus
 */

// Request type definition
export type GetActivationStatusT = r.IGetApiRequestType<
  { readonly codiceContestoPagamento: string },
  never,
  never,
  | r.IResponseType<200, PaymentActivationsGetResponse, never>
  | r.IResponseType<400, ProblemJson, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<404, ProblemJson, never>
  | r.IResponseType<500, ProblemJson, never>
>;

export const getActivationStatusDefaultResponses = {
  200: PaymentActivationsGetResponse,
  400: ProblemJson,
  401: t.undefined,
  404: ProblemJson,
  500: ProblemJson
};

export type GetActivationStatusResponsesT<
  A0 = PaymentActivationsGetResponse,
  C0 = PaymentActivationsGetResponse,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = ProblemJson,
  C4 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
  500: t.Type<A4, C4>;
};

export function getActivationStatusDecoder<
  A0 = PaymentActivationsGetResponse,
  C0 = PaymentActivationsGetResponse,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = ProblemJson,
  C4 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetActivationStatusResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4>
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<400, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<404, A3, never>
  | r.IResponseType<500, A4, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getActivationStatusDefaultResponses as unknown) as GetActivationStatusResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, never>(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        never
      >(400, type[400])) as r.ResponseDecoder<r.IResponseType<400, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, never>(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        never
      >(500, type[500])) as r.ResponseDecoder<r.IResponseType<500, A4, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
      d404
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getActivationStatusDefaultDecoder = () =>
  getActivationStatusDecoder();

/****************************************************************
 * PostNewslettersEmails
 */

// Request type definition
export type PostNewslettersEmailsT = r.IPostApiRequestType<
  {
    readonly idList: string;
    readonly idClient: string;
    readonly body: RecipientRequest;
  },
  "Content-Type",
  never,
  | r.IResponseType<200, RecipientResponse, never>
  | r.IResponseType<400, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<500, undefined, never>
>;

export const postNewslettersEmailsDefaultResponses = {
  200: RecipientResponse,
  400: t.undefined,
  403: t.undefined,
  500: t.undefined
};

export type PostNewslettersEmailsResponsesT<
  A0 = RecipientResponse,
  C0 = RecipientResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  500: t.Type<A3, C3>;
};

export function PostNewslettersEmailsDecoder<
  A0 = RecipientResponse,
  C0 = RecipientResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<PostNewslettersEmailsResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<400, A1, never>
  | r.IResponseType<403, A2, never>
  | r.IResponseType<500, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((postNewslettersEmailsDefaultResponses as unknown) as PostNewslettersEmailsResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, never>(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        never
      >(400, type[400])) as r.ResponseDecoder<r.IResponseType<400, A1, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A2, never>>;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, never>(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        never
      >(500, type[500])) as r.ResponseDecoder<r.IResponseType<500, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d403),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const PostNewslettersEmailsDefaultDecoder = () =>
  PostNewslettersEmailsDecoder();

/****************************************************************
 * GetBrowsersInfo
 */

// Request type definition
export type GetBrowsersInfoT = r.IGetApiRequestType<
  {},
  never,
  never,
  | r.IResponseType<200, BrowserInfoResponse, never>
  | r.IResponseType<400, undefined, never>
  | r.IResponseType<500, undefined, never>
>;

export const getBrowsersInfoDefaultResponses = {
  200: BrowserInfoResponse,
  400: t.undefined,
  500: t.undefined
};

export type GetBrowsersInfoResponsesT<
  A0 = BrowserInfoResponse,
  C0 = BrowserInfoResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  500: t.Type<A2, C2>;
};

export function GetBrowsersInfoDecoder<
  A0 = BrowserInfoResponse,
  C0 = BrowserInfoResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined
>(
  overrideTypes:
    | Partial<GetBrowsersInfoResponsesT<A0, C0, A1, C1, A2, C2>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<400, A1, never>
  | r.IResponseType<500, A2, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getBrowsersInfoDefaultResponses as unknown) as GetBrowsersInfoResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, never>(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        never
      >(400, type[400])) as r.ResponseDecoder<r.IResponseType<400, A1, never>>;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, never>(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        never
      >(500, type[500])) as r.ResponseDecoder<r.IResponseType<500, A2, never>>;

  return r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d500);
}

// Decodes the success response with the type defined in the specs
export const GetBrowsersInfoDefaultDecoder = () => GetBrowsersInfoDecoder();
