"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.and = exports.or = exports.not = exports.Contravariant = exports.getMonoidAll = exports.getSemigroupAll = exports.getMonoidAny = exports.getSemigroupAny = exports.URI = exports.contramap = void 0;
var function_1 = require("./function");
// -------------------------------------------------------------------------------------
// type class members
// -------------------------------------------------------------------------------------
var contramap_ = function (predicate, f) { return function_1.pipe(predicate, exports.contramap(f)); };
/**
 * @category Contravariant
 * @since 2.11.0
 */
var contramap = function (f) { return function (predicate) { return function_1.flow(f, predicate); }; };
exports.contramap = contramap;
// -------------------------------------------------------------------------------------
// instances
// -------------------------------------------------------------------------------------
/**
 * @category instances
 * @since 2.11.0
 */
exports.URI = 'Predicate';
/**
 * @category instances
 * @since 2.11.0
 */
var getSemigroupAny = function () { return ({
    concat: function (first, second) { return function_1.pipe(first, exports.or(second)); }
}); };
exports.getSemigroupAny = getSemigroupAny;
/**
 * @category instances
 * @since 2.11.0
 */
var getMonoidAny = function () { return ({
    concat: exports.getSemigroupAny().concat,
    empty: function_1.constFalse
}); };
exports.getMonoidAny = getMonoidAny;
/**
 * @category instances
 * @since 2.11.0
 */
var getSemigroupAll = function () { return ({
    concat: function (first, second) { return function_1.pipe(first, exports.and(second)); }
}); };
exports.getSemigroupAll = getSemigroupAll;
/**
 * @category instances
 * @since 2.11.0
 */
var getMonoidAll = function () { return ({
    concat: exports.getSemigroupAll().concat,
    empty: function_1.constTrue
}); };
exports.getMonoidAll = getMonoidAll;
/**
 * @category instances
 * @since 2.11.0
 */
exports.Contravariant = {
    URI: exports.URI,
    contramap: contramap_
};
// -------------------------------------------------------------------------------------
// utils
// -------------------------------------------------------------------------------------
/**
 * @since 2.11.0
 */
var not = function (predicate) { return function (a) { return !predicate(a); }; };
exports.not = not;
/**
 * @since 2.11.0
 */
var or = function (second) { return function (first) { return function (a) { return first(a) || second(a); }; }; };
exports.or = or;
/**
 * @since 2.11.0
 */
var and = function (second) { return function (first) { return function (a) { return first(a) && second(a); }; }; };
exports.and = and;
